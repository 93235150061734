@import url('https://fonts.googleapis.com/css2?family="Inter", sans-serif:wght@400;500;600;700&display=swap');

:root {
  --hs-light-orange: #fff2ee;
  --hs-dark-orange: #fb784e;
  --hs-light-yellow: #fffbf0;
  --hs-yellow: #ffc327;
  --hs-violet: #605bff;
  --hs-blue: #5b93ff;
  --hs-light-blue: #f5f4ff;
  --hs-light-blue-2: #e6edff;
  --hs-v-light-blue: #e5eeff;
  --hs-light-green: #d5fce3;
  --hs-light-red: #fff4f5;
  --hs-v-light-red: #ffe5e5;
  --hs-pink: #f22f46;
  --hs-light-pink: #ffeded;
  --hs-red: #e71d36;
  --hs-light-grey: #f8f9fa;
  --hs-v-light-grey: #f5f5f5;
  --hs-light-silver: #f0f0ff;
  --hs-dark-grey: #374151;
  --badge-light-blue: 91, 147, 255;
  --badge-light-red: 251, 120, 78;
  --badge-light-grey: 143, 143, 143;
  --badge-light-green: 58, 151, 76;
  --hs-dark-blue: #111827;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

* {
  font-family: "Inter", sans-serif !important;
}
pre {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0px !important;
  word-break: break-word !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.loading::before {
  content: "";
  position: fixed;
  top: -50vh;
  left: -50vw;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  z-index: 0;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.loading span {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: var(--hs-violet);
  border-radius: 50%;
  margin: 0 3px;
  animation: moveup 0.6s ease-in-out infinite alternate;
}

@keyframes moveup {
  100% {
    transform: translateY(-25px);
  }
}
.loading span:nth-child(2) {
  background-color: #00f8c4;
  animation: moveup 0.6s ease-in-out infinite alternate;
  animation-delay: 0.25s;
}
.loading span:nth-child(3) {
  animation: moveup 0.6s ease-in-out infinite alternate;
  animation-delay: 0.5s;
}
