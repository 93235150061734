.statistics-data {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

.statistics-data > .ant-statistic-title {
  font-size: 12px;
}

.statistics-data .ant-statistic-content-value-int {
  font-weight: 700;
  font-size: 19px;
  margin-inline-start: 1px;
}
.campaign-analytic-sprites {
  background-repeat: no-repeat;
  background: url(../../assets/svg/static-icons.svg);
  width: 55px;
  min-width: 37px;
  height: 55px;
  min-height: 55px;
  border-radius: 4px;
  transform: translate(0%, 0%) scale(1);
  background-size: 34rem 36rem;
}
.ci-sent {
  background-position: -277px -297px;
}
.ci-totalProcessed {
  background-position: -173px -84px;
}
.ci-success {
  background-position: -373.8px -84px;
}
.stepOneAuthorizeIcon {
  background-position: -509.8px -121px;
}
.stepTwoAuthorizeIcon {
  background-position: -36.8px -196px;
  height: 51px !important;
}
.ci-failure {
  background-position: -305px -84px;
}
.ci-skipped {
  background-position: -237px -84px;
}
.ci-inProgress {
  background-position: -109px -84px;
}

.card-analytics {
  display: flex;
  align-items: center;
}
.card-row {
  width: 100%;
}
.campaign-Chart {
  border-radius: 12px;
  margin: auto;
}
.error-codes {
  background-color: #fff;
  border-radius: 12px;
}

.errorCodes > ul > li {
  list-style: 1rem;
}
.campaignErrorsTable thead .ant-table-cell {
  background-color: #fff !important;
  /* color: white !important; */
  font-size: 12px;
  font-weight: 400 !important;
}
.campaignErrorsTable .ant-table-container {
  box-shadow: none;
  padding: 3rem;
}
.campaignErrorsTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 1px;
}
.campaignErrorsTable.ant-table-wrapper table {
  border: none;
}
.campaignErrorsTable .ant-table-content {
  padding: 2rem;
}
.campaignErrorsTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}
.campaignErrorsTable.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: center;
}
.campaignErrorsTable .ant-table-tbody > tr > td {
  text-align: center;
  width: 3rem;
  max-width: 3rem;
  /* color: #6a707e; */
}
.campaignErrorsTable .ant-table-tbody > tr.ant-table-row:hover > td {
  color: #605bff;
  background-color: inherit;
}
.campaignErrorsTable.ant-table-wrapper
  .ant-table-thead
  > tr:not(:last-child)
  > th[colspan] {
  border-bottom: 1px solid #ededed !important;
}
.campaignDetailMenu {
  width: 220px;
}
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom-width: 0px !important;
  transition: 0s !important;
}
.campaignDetailMenu.ant-menu-horizontal {
  border-bottom: 0px;
  background-color: #fff !important;
  border-radius: 12px;
}
.campaignDetailMenu.ant-menu .ant-menu-title-content {
  font-size: 12px;
}
.campaignDetailMenu.ant-menu-horizontal .ant-menu-item {
  padding-inline: 0px;
}
.colorDefine {
  height: 25px;
  width: 25px;
  border-radius: 6px;
}
.error-codes-list.ant-list-split .ant-list-item {
  border-block-end: 0px;
}
.errorCodesListContainer {
  height: 45vh;
  overflow: auto;
}
.campaign-menu-button {
  border-inline: 0px !important;
  border-width: 0px;
  height: 41px !important;
  display: flex;
  align-items: center;
}
.anti-hover:hover {
  color: black !important;
}
.campaign-menu {
  border-radius: 8px !important;
  background-color: #fff !important;
  display: flex;
  align-items: center;
}
.campaign-menu .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.overview-btn {
  background-color: var(--hs-violet) !important;
  color: #fff !important;
}

.campaignActionContainer {
  min-height: 5dvh;
}

.campaignActionTitleContainer {
  height: 100%;
}

.reportCardRoot {
  width: 14rem;
  height: 7rem;
  box-shadow: none !important;
}
