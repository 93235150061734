.landingPageText {
  font-size: 1.3rem;
  font-family: "Inter", sans-serif;
  margin-top: 3rem;
  text-align: center;
  font-weight: 700;
}
.checkPhoneBtn {
  height: 310px;
  width: 350px;
  font-size: 17px;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  border-radius: 12px;
  color: #000000;
  border: 2px solid;
  border-radius: 12px;
  border-color: #cfcfcf;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.checkPhoneBtnSelected {
  height: 310px;
  width: 350px;
  font-size: 17px;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  border: 2px solid;
  border-radius: 12px;
  border-color: #009efd;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.prerequiste {
  flex-grow: 1;
  background-color: #f5f5f5;
  color: #636363;
  display: flex;
  text-align: left;
  padding: 8px;
  font-size: 13px;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.checkPhoneNextBtn {
  height: 60px;
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin-top: 30px;
  width: 500px;
  border: 2px solid;
  border-radius: 4px;
  background-color: #009efd;
}
.buyNumberMenu {
  border-bottom: 0px;
  text-align: center;
}
.buyNumberMenuItem {
  width: 49%;
  color: #90a0b7;
}
.buyNumberMenuItem .ant-menu-submenu-selected::after {
  color: #605bff !important;
}
.buyNumberDrawer .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #605bff !important;
}
.buyNumberHeaderSpan {
  padding-left: 2.5rem !important;
}
.buyNumberHeaderTextColor {
  font-weight: 500;
  color: #334d6e;
}
.buyNumberCountrySelection {
  padding-left: 2rem !important;
}
.buyNumberDrawer .ant-drawer .ant-drawer-title {
  /* color: #334D6E !important; */
  color: red !important;
  font-size: 12px;
}
.buyNumberDrawer .ant-drawer-body {
  padding: 0px;
}
.buyNumberDrawer .ant-select-selector {
  /* border: 0px !important; */
  /* width: 7rem !important; */
}
.selectTypeSelect .ant-select-selector {
  /* width: 7rem !important; */
}
.buyNumberDrawer {
  width: 500px;
}
.localInput {
  width: 85%;
}

.buyNumberDrawer .ant-table-wrapper .ant-table-tbody > tr > td,
.buyNumberDrawer .ant-table-wrapper .ant-table-thead > tr > th {
  padding-left: 4rem !important;
}
.buyNumberDrawer .ant-table-tbody {
  margin-left: 2rem;
  padding-right: 2rem;
}
.buyNumberDrawer .ant-table-container {
  box-shadow: none;
}
/* .buyNumberDrawer .ant-select .ant-select-arrow{
    color: #009EFD;
} */
/* .buyNumberDrawer .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,.buyNumberDrawer .ant-select-selector{
    border: 0px !important;
    color: #009EFD; 
    opacity: 60%;
    font-size: 11px;
    background-color: #F6FCFF !important;
} */
.inputsRow {
  background-color: #f6fcff;
  text-align: center;
}
.creditsBuyNumber {
  /* color: #009EFD; */
  color: #334d6e;
  font-weight: bold;
  font-size: 14px;
}
.numberList {
  font-size: 14px;
  color: #334d6e;
}
.addressVerifyInput {
  height: 40px;
  border-radius: 4px;
}
.addressVerifyInput2 {
  height: 40px;
  border-radius: 4px;
}
.personalDetailsForm
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px;
}
.addressCard {
  width: 88%;
  font-family: "Inter", sans-serif;
  margin: auto;
  margin-bottom: 50px;
}
.friendlyNameInput {
  height: 40px;
  width: 300px;
  border-radius: 4px;
}
.numberVerifyModal.ant-modal .ant-modal-title {
  font-weight: 400;
}
.numberVerifyModal {
  font-family: "Inter", sans-serif;
}
.verifyBtn {
  float: right;
  width: 280px;
  height: 50px;
  border-left: 6px solid;
  border-radius: 4px;
  border-color: #605bff;
  text-align: left;
}
.numberVerifyModal.ant-modal
  .ant-modal-footer
  .ant-btn
  + .ant-btn:not(.ant-dropdown-trigger) {
  height: 40px;
  width: 200px;
  margin-right: 6%;
  margin-left: 2%;
  border-radius: 4px;
}
.cancelBuyButton {
  height: 35px;
  border-radius: 4px;
  width: 75px;
  margin-top: 2.5px;
  margin-right: 30px;
}
.createAddressBtn {
  height: 40px;
  border-radius: 4px;
  width: 150px;
  margin-right: 20px;
  background-color: #605bff;
}
/* .buyNumberDrawer .ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after ,
  .buyNumberDrawer .ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after{
    width: 50%;
    color: #009EFD;
    margin: auto;
  }
 */

.personalDetailsForm {
  padding-top: 20px;
  padding-left: 25px;
  font-family: "Inter", sans-serif !important;
}
.personalDetailsForm .ant-form-item .ant-form-item-control-input-content {
  max-width: 80%;
}
.verifyMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #334d6e;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.verifyMenu.ant-menu .ant-menu-item {
  color: #90a0b7;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.verifyMenu.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: #334d6e;
}
.buyNumber {
  display: flex;
  justify-content: flex-end;
}
.buyNumberBtn {
  height: 40px;
  width: 220px;
  background-color: #605bff;
  color: #fff;
}
.buyNumberFooter {
  /* float: right; */
  display: flex;
  justify-content: flex-end;
}
.numberVerifyModal.ant-modal .ant-modal-content {
  height: auto;
}
.reviewNumber {
  padding: 20px;
  font-family: "Inter", sans-serif;
}
* {
  font-family: "Inter", sans-serif;
}
.friendlyName {
  margin-top: 50px;
}
.buyNumberDrawer .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  font-weight: 600;
}
.buyNumberDrawer
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
.buyNumberDrawer
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  width: 50%;
  margin: auto;
  border-bottom-color: #605bff;
}
.numberVerifyModal
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
.numberVerifyModal
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  width: 50%;
  margin: auto;
}
.buyNumberCountrySelection .ant-select-selection-item {
  font-weight: 500;
  color: #6c757d !important ;
  /* overflow: auto; */
}
.buyNumberTable.ant-table-wrapper .ant-table-thead > tr > th {
  color: #6c757d !important ;
  font-size: 14px;
}
.buyNumberPopover .ant-popover-content {
  width: 350px;
}
.fundsUI.ant-btn.ant-btn-round {
  padding-inline-start: 10px;
  padding-inline-end: 12px;
}
.fundsUI {
  display: flex;
  flex-direction: row;
}
.fundsVal.ant-space {
  gap: 0px !important;
}

.chargeebeeDrawer .ant-drawer .ant-drawer-body {
  background-color: #10182f;
}
.chargeebeeDrawer {
  background-color: #10182f !important;
}
.chargeebeeDrawer .ant-drawer-body {
  padding-top: 0px !important;
}
.selectTypeSelect .ant-select-selection-item,
.selectTypeSelect .ant-select-arrow {
  color: #009efd !important;
}
.countrySelectList .ant-select-selection-item,
.countrySelectList .ant-select-arrow {
  color: #009efd !important;
}

.selectCombo {
  border: 1px solid #d8f0ff;
  border-radius: 37px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.faq-chargebee {
  color: white;
  font-size: 13px;
}
.chargebee-iframe {
  margin: 0 auto;
  background: #10182f;
  overflow: hidden !important;
  transform: scale(0.9);
}
@media (min-width: 1750px) {
  .head-chargebee {
    font-size: 24px !important;
    margin-top: 10px !important;
  }
  .faq-chargebee {
    font-size: 15px;
  }
  .chargebee-iframe {
    transform: scale(1);
    margin: 25px auto;
  }
}
.close-btn {
  background-color: rgba(193, 131, 138, 0.1);
  color: #e71d36;
}
.close-btn > .ant-btn-icon > .anticon-close {
  font-size: 12px;
}
