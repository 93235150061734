.frame {
  align-items: flex-start;
  background-color: #fec00f1a;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 15px 30px;
  position: relative;
  width: 1339px;
  font-family: "Inter ", sans-serif;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "" Inter ", sans-serif-SemiBold", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -20px;
  margin-top: -1px;
  position: relative;
  width: 1279px;
}

.frame .element-select-an-SMS {
  color: transparent;
  font-family: "" Inter ", sans-serif-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1px;
  margin-right: -20px;
  position: relative;
  width: 1279px;
}

.frame .span {
  color: #000000;
  font-weight: 500;
}

.frame .text-wrapper-2 {
  color: #5f5f5f;
  font-family: "" Inter ", sans-serif-Regular", Helvetica;
}

.frame .text-wrapper-3 {
  color: #a6a6a6;
  font-family: "" Inter ", sans-serif-Italic", Helvetica;
  font-style: italic;
}

.frame .text-wrapper-4 {
  color: #000000;
  font-family: "" Inter ", sans-serif-Italic", Helvetica;
  font-style: italic;
}

.frame .div {
  color: transparent;
  font-family: "" Inter ", sans-serif-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
