* {
  font-family: "Inter", sans-serif;
}
.phoneLogoSprites {
  background-repeat: no-repeat;
  background: url(../../images/helloSendIcons.png);
  background-size: 812px 364px;
}
.phone {
  min-width: 26px;
  border-radius: 37px;
  height: 26px;
  background-position: 173px 259px;
}
.pages {
  width: 26px;
  min-width: 26px;
  border-radius: 37px;
  height: 26px;
  transform: translate(0%, 0%) scale(2);
  background-position: 172.5px 311px;
}
.delete-icon {
  min-width: 29px;
  width: 29px;
  height: 36px;
  transform: translate(0%, 0%) scale(2.5);
  background-position: 127.6px 318.5px;
}
.listPhone {
  height: 25px;
  width: 26px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: 172.5px 258px;
}
.editPhone {
  height: 14px;
  width: 14px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: 15.3px 311.7px;
  cursor: pointer;
}
.addPhoneSwitch {
  height: 14px;
  width: 20px;
  min-width: 36px;
}
.addPhoneSwitch .ant-switch-handle {
  top: -3px;
  inset-inline-start: 0px;
  width: 20px;
  height: 20px;
}
.addPhoneSwitch.ant-switch.ant-switch-checked {
  background: #78ea93 !important;
}
.addPhoneSwitch.ant-switch.ant-switch-checked.ant-switch
  .ant-switch-handle::before {
  border-radius: 37px;
  background: #35c658;
}
.editPhoneInputs {
  background-color: #f7f7f8 !important;
  height: 48px;
  width: 100%;
  margin-top: 6px;
}
.editPhoneCancelBtn {
  font-weight: 500;
}
.editPhoneAddBtn {
  color: #fff;
  width: 6rem;
  font-weight: 600;
  background-color: #2ed47a;
}
.editPhoneAddBtn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #2ed47a;
}
.editPhoneLabel {
  color: #030229;
  display: flex;
  flex-direction: column;
}
.phoneAddTable .ant-table-container {
  box-shadow: none;
}
.phoneAddTable.ant-table-wrapper .ant-table-thead > tr > th {
  color: #030229 !important ;
  font-size: 14px;
  background-color: white;
}
.phoneAddTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px !important;
}
.phoneAddTable {
  margin: 0.5rem 1rem;
}
.phoneAddTable.ant-table-wrapper table {
  border: none;
  border-left: #d0d0d0 solid 0px;
  border-right: #d0d0d0 solid 0px;
}
.addPhoneMenu {
  width: 101%;
}
.addPhoneMenu .ant-menu-overflow-item {
  padding-left: 0px;
  color: #374151;
}
.addPhoneMenu .ant-menu-overflow-item span {
  color: #374151;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom-color: #605bff;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-color: #605bff;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #030229 !important;
  font-weight: 600;
  font-size: 15px;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-width: 0px;
}
.configPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #030229 !important;
  font-weight: bold;
  font-size: 15px;
}
.configPhoneMenu.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom-width: 0px;
}
.configPhoneMenu.ant-menu-horizontal {
  border-bottom: 0px;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item {
  color: #6a707e;
}
.configPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item {
  color: #6a707e;
}
.edit-head {
  font-size: 15px;
  font-weight: 600;
}
.edit-head-phone {
  font-size: 15px;
  font-weight: 600;
  margin-top: 1rem;
}
.editPhoneInputs:focus {
  border: 2px solid #605bff;
}
.number-info-sprite {
  background-position: 74% 13.8%;
  width: 20px !important;
  height: 20px !important;
}
