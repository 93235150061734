.PricingPage {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .pricingCard .ant-card-body{
    padding-bottom: 0px !important;
    padding-top: 15px !important;
    margin-right: -15px;
  }
  .pricingCard .ant-card-head{
    margin-right: -15px;

  }

  .over{
    background: linear-gradient(to right, #009EFD,
    #40C3F7);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  .successPricingModel{
    width: 400px !important;
    height:416px;
  }

  .featureList li {
    padding-top: 14px;
    font-weight: 400;
    font-size: 12px;
    color:#486581;
  }
  .featureList li:empty{
    display:none;
  }
  
  .inner-1 {
    border-right: 1px solid black;
  }
  .right{
    width: 50px;
  }
  .left,
  .right {
    height: auto;
    overflow-y: scroll;
  }
  
  .left {
    border-right: 1px solid black;
  }
  
  
  .App-link {
    color: #61dafb;
  }

.featureList{
  list-style-type: none;
  padding-left: 0rem;
}

.oappsLogo{
  height: 28px;
  width: 92px;
  margin-top: 15px;
  margin-left:53px;
  margin-bottom: 15px;
}
.getStarted{
  background-color: #009EFD;
  width:248px;
  height:48px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 25px;
}
.pricingRow .ant-row{
flex-flow: inherit;
}

#components-collapse-demo-collapsible .ant-space {
  width: 100%;
}

.PricingPart{
  position: fixed;
  padding-top: 75px;
  padding-right: 5px;
}
.FAQTitle{
  font-weight: 700;
  font-family: inter;
  font-size: 32px;
}

.FAQIcon{
 position: fixed;
}

.pricingCard{
  width: 331px; 
  height: 80dvh; 
  padding-top: 5px;
}

@media (max-width:750px) {
  .pricingContent{
    width: 400px !important;
  }
  .PricingPart{
    position: relative;
    margin: auto;
  }
  .pricingContent{
    flex-direction: row;
  } 
  .pricingRow{
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
.FAQRow{
  padding-left: 15px !important;
  margin-left: 15px !important;
}
.FAQIcon{
  display: none;
 }
 #FAQ{
  padding-left: 0vh;
 }
 .FAQTitle{
  padding-top: 0px !important;
  margin-top: 0px !important;
 }
 .FAQDiv{
  margin-left: 0px !important;
 }
 .over{
  padding-top: 0px !important;
  margin-top: 0px !important;
 }
 .featureList li{
  font-size: 25px !important;
 }

 .getStarted{
  margin-right: 30%;
 }
 .pricingCard{
  width: 100% !important; 
  height: auto; 
  left: 0px;
  padding-top: 5px;
  }
  .Yearly{
    margin-left: 0px !important;
    padding-left:0px !important;
  }
  .pricingCard .ant-card-body{
    padding-left: 15% !important;
  }
  .listTitle{
    font-size: 35px !important;
  }
  .pricingCol{
    margin-right: 0px !important;
    width: 100% !important;
  }
  .Yearly .ant-row{
    display: block;
  }
  .Yearly .ant-row .ant-col .ant-space{
    display: block;
  }
  .checkMark{
    width: 25px;
  }
}

.getStarted:hover,
.getStarted:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.5em);
  background-color: #009EFD !important;
}