.chatList {
  cursor: pointer;
}
.servicesSelect {
  background-color: rgba(155, 157, 161, 0.1);
  border-radius: 37px;
  height: 35px;
  color: #1d1d1d !important;
}
.servicesSelect:hover {
  background-color: rgba(155, 157, 161, 0.3);
}
.failedMessage {
  background-color: #ffe9ec;
  color: black !important;
  min-height: 33px;
  border-radius: 8px;
  padding: 0px 4px;
  word-break: break-all;
}
.deliveredMessage {
  background-color: #605bff;
  color: #fff;
  min-height: 33px;
  border-radius: 8px;
  padding: 0px 4px;
  word-break: break-all;
}
.receivedMessage {
  border-radius: 8px;
  color: black;
  word-break: break-all;
  min-height: 33px;
  background: #f1f1f1;
}
.sendOptionBtn {
  background-color: #edfbf4;
  color: #2ed47a;
}
.sendOptionBtn:hover {
  color: green !important;
}
.sendSmsSelect .ant-select-selector {
  border-radius: 37px;
  background-color: lightgrey !important;
  border: 0px !important;
}
.deliveredMessage .ant-image .ant-image-img,
.receivedMessage .ant-image .ant-image-img {
  width: 101%;
}
.conversationHeader {
  min-height: 4.5rem;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 1px solid #f1f3f9;
}

.rightService-spirites {
  background: url("../images/RightSerLogos.png");
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 36px 313px;
  transform: translate(0%, 0%) scale(1.3);
  border-radius: 37px;
  cursor: pointer;
}
.Twilio-icon {
  background-position: -8px -273px;
}
.RingCentral-icon {
  background-position: -8px -10.5px;
}
.Vonage-icon {
  background-position: -8px -174.5px;
}
.Telnyx-icon {
  background-position: -8px -43.5px;
}
.Plivo-icon {
  background-position: -8px -76px;
}
.BurstSMS-icon {
  background-position: -8px -109px;
}
.ClickSend-icon {
  background-position: -8px -142px;
}
.Facebook {
  background-position: -8px -207px;
}
.Instagram {
  background-position: -8px -240px;
}

.badgeSprites {
  background: url("../svg/static-icons.svg");
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: 608px 691px;
  transform: translate(0%, -0%) scale(0.8) !important;
}

.RingCentral-badgeIcon {
  background-position: 42.8% 27.8%;
}
.Twilio-badgeIcon {
  background-position: 85% 27.8%;
}
.Hello-badgeIcon {
  background-position: 42.8% 37%;
}
.Telnyx-badgeIcon {
  background-position: 53.5% 27.8%;
}
.Vonage-badgeIcon {
  background-position: 63.9% 27.8%;
}
.Plivo-badgeIcon {
  background-position: 89% 6.6%;
}
.BurstSMS-badgeIcon {
  background-position: 95.5% 27.8%;
}
.ClickSend-badgeIcon {
  background-position: 74.5% 27.8%;
}
.Facebook-badgeIcon {
  background-position: 64% 37%;
}
.WhatsApp-badgeIcon {
  background-position: 53.4% 37%;
}
.Instagram-badgeIcon {
  background-position: 74.5% 36.8%;
}
.chatWrap {
  margin-inline-start: 30% !important;
}
.close-icon-sprite {
  background-position: -90px -5px;
  transform: translate(0%, -10%) scale(1) !important;
  height: 27px;
}
.noConvo-icon {
  height: 150px;
  width: 150px;
}
@media (max-width: 600px) {
  .noConvo-info > span:nth-child(1) {
    font-size: 12px;
  }
  .noConvo-info > span:nth-child(2) {
    font-size: 10px;
  }
  .noConvo-icon {
    height: 100px;
    width: 100px;
  }
}
.refresh-conv {
  position: absolute;
  right: 14px;
  z-index: 1000;
  cursor: pointer;
}
.refresh-icon {
  height: 27px !important;
  width: 27px !important;
  background-position: 21% 27.7%;
  background-size: 40rem 46rem;
}
.receivedMessage .ant-image .ant-image-mask,
.deliveredMessage .ant-image .ant-image-mask {
  border-radius: 8px;
  margin-top: 3px;
}
.textArea-Wrap:focus {
  outline: 2px #605bff;
}
.fromNumber-conv {
  background-color: #edfbf4;
  color: #2ed47a;
  width: 350px !important;
  margin-right: 1rem;
  height: 37px !important;
}
.chatIcon-sprite {
  height: 100px !important;
  width: 115px !important;
  background-position: 18% 64.5%;
  transform: translate(0%, 0%) scale(0.9);
  background-size: 47rem 43rem;
}
.numberlistEmpty-icon {
  height: 100px !important;
  width: 100px !important;
  background-position: 25% 54%;
  transform: translate(0%, 0%) scale(1.2);
  background-size: 65rem 60rem;
}
.inboxNumberListEmpty-icon {
  height: 100px !important;
  width: 100px !important;
  background-position: 14% 52%;
  transform: translate(0%, 0%) scale(1.2);
  background-size: 65rem 60rem;
}
.search-empty-icon {
  height: 60px !important;
  width: 100px !important;
  background-position: 25% 52.5%;
  transform: translate(0%, 0%) scale(1);
  background-size: 65rem 60rem;
}
.numberlist-count {
  padding: 1px 10px;
  background: #f0f0ff;
  font-size: 12px;
  border-radius: 11px;
  cursor: default;
}
.conv-textArea.ant-input-affix-wrapper:focus-within {
  box-shadow: none;
}
.list-row-avatar {
  background-color: #f3f3f3;
  height: 48px;
  width: 48px;
}
.pdf-icon {
  min-height: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  background-size: 30rem 28rem;
  background-position: 35.5% 46%;
}
.doc-icon {
  min-height: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  background-size: 30rem 28rem;
  background-position: 43.8% 46%;
}
.conv-list-item:hover {
  background-color: rgba(245, 245, 255, 1);
}
.pdfAttch {
  border-radius: 6px;
  color: #0a88ff;
  margin: 2px 0px;
  display: flex;
  align-items: center;
  min-width: 7rem;
  height: 50px;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
}

.avatarIcon {
  background-position: -18.25rem -19.1rem;
}
.input-error {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(2.5px);
  }
  75% {
    transform: translateY(-2.5px);
  }
  100% {
    transform: translateY(0);
  }
}
.conv-attachments {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-gap: 0px 5px;
}
.image-container {
  background-color: #f2f3f4;
  border-radius: 8px;
  padding: 3px 4px 5px 8px;
}
.image-failed {
  filter: blur(4px);
}
.image-background {
  /* filter: blur(8px); */

  /* -webkit-filter: blur(8px); */
}
.queue-icon {
  background-position: 13.7% 19.4%;
  background-size: 271px 295px;
  height: 12px !important;
  width: 12px !important;
}
.contact-queue-icon {
  background-position: 17.4% 19.2%;
  background-size: 365px 355px;
  height: 16px !important;
  width: 13px !important;
}
.contact-failure-icon {
  background-position: 61.55% 19.3%;
  background-size: 663px 671px;
  height: 16px !important;
  width: 13px !important;
}
.failure-icon {
  background-position: 63.5% 19.5%;
  background-size: 560px 652px;
  height: 12px !important;
  width: 12px !important;
  cursor: default !important;
}
.success-icon {
  background-position: 78.4% 1.7%;
  background-size: 572px 569px;
  height: 12px !important;
  width: 12px !important;
}
.sent-icon {
  background-position: 78.4% 1.5%;
  background-size: 663px 671px;
  height: 16px !important;
  width: 13px !important;
}
.delivered-icon {
  background-position: 78.4% 3.9%;
  background-size: 572px 569px;
  height: 12px !important;
  width: 16px !important;
}
.contact-delivered-icon {
  background-position: 78.4% 3.7%;
  background-size: 667px 671px;
  height: 16px !important;
  width: 15px !important;
}
.message-retry-icon {
  background-position: 63.25% 27.1%;
  background-size: 400px 400px;
  height: 17px !important;
  width: 17px !important;
}
.message-retry {
  position: absolute;
  left: -24px;
  bottom: 9px;
}
.contact-image-icon {
  background-position: 83% 9.2%;
  height: 17px !important;
  width: 17px !important;
  display: block;
}
.contact-file-icon {
  background-position: 83% 13%;
  height: 17px !important;
  width: 17px !important;
  display: block;
}
.contact-video-icon {
  background-position: 83.4% 19.8%;
  height: 17px !important;
  width: 20px !important;
  display: block;
}
.contact-gif-icon {
  background-position: 83.5% 16.75%;
  height: 17px !important;
  width: 21px !important;
  display: block;
}
.searchFilter-contact.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child {
  display: none;
}
.clear-search {
  background-position: 15.7% 1.2%;
  transform: translate(0px, 0px) scale(0.8);
}

.search-loading {
  background-position: 11.5% 27.5%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.search-input-clear {
  cursor: pointer;
  text-decoration: underline;
}
