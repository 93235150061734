
body {
  font-family: "Inter", sans-serif, sans-serif;
}

.savedUserTable {
  width: 82rem;

  border-radius: 8px 8px 0 0;

  margin-top: 1rem;
}

.savedUserTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d0d0d0;
}
.savedUserTable thead .ant-table-cell {
  background-color: #009efd !important;
  color: white !important;
  line-height: 1;
  font-size: 12px;
  font-family: "Inter",sans-serif;
}

.savedUserTable .ant-table-wrapper table {
  border-left: #d0d0d0 solid 1px;
  border-right: #d0d0d0 solid 1px;
}

.saveduserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}
.savedUserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

#addUserBtn {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  /* margin-top: 2rem; */

  border: none;
  background-color: #f9f9fb;
  color: #009efd;
}
#emailInput {
  width: 13rem;
  height: 2.5rem;
}
#email {
  font-family: "Inter", sans-serif;
}
.usersAddButton {
  text-align: end;
}
.usersAddButton.ant-btn.ant-btn-lg {
  border-radius: 4px;
}
#usersAdd {
  background-color: #109cf1;
  width: 5rem;
  height: 2.5rem;
}
#fdnameError {
  color: #ee3248;
  font-size: 10px;
}
#freindlyNameEg {
  font-size: 10px;
}
.selectUser {
  width: 100% !important;
}

.usersEmail {
  white-space: pre-line;
}

.userDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.deleteUserIcon {
  background: url("../images/icons.png") -239px -235.9px;
}

.deleteUserIcon:hover {
  background: url("../images/icons.png") -111px -232.9px;
}