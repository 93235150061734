.actionIconsSprite {
  height: 27px;
  width: 25px;
  background-image: url("../svg/action-icons.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.actionIconsSpriteForSideBar {
  height: 40px;
  width: 30px;
  background-image: url("../svg/action-icons.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.staticIconsSprite {
  height: 25px;
  width: 25px;
  background-image: url("../svg/static-icons.svg");
  background-repeat: no-repeat;
}
