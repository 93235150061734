.parent-element {
  height: auto;
  width: 100%;
  max-width: 550px;
}

.plusIcon {
  font-size: 21px;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  cursor: pointer;
}

.plusIcon :hover {
  font-size: 21.5px;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.border-color-primary,
.custom-drop-down-select .ant-select-selector {
  border-color: #2499ef !important;
}

.buy-now-indicator-red {
  color: #ffe7e3;
}

.buy-now-indicator-red-dark {
  color: #cb6565;
}

.buy-now-indicator-green {
  color: #faf6ca;
}

.buy-now-indicator-green-dark {
  color: #b19a52;
}

.buy-now-indicator-blue {
  color: #e7eefe;
}

.buy-now-indicator-blue-dark {
  color: #6361c6;
}

.message-sent-failure {
  color: #d25722;
}

.message-sent-success {
  color: #30c224;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.custom-drop-down-select .ant-select-selector {
  background: #fff !important;
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.075) !important;
}

.custom-drop-down-select .ant-select-selection-placeholder,
.custom-drop-down-select .ant-select-arrow,
.color-primary,
.custom-drop-down-select .ant-select-selection-item {
  color: #2499ef;
}

.custom-drop-down-select .ant-select-selection-placeholder,
.custom-drop-down-select .ant-select-selection-item,
.custom-drop-down-select .ant-select-arrow {
  font-size: 12px !important;
}

.custom-drop-down-select .ant-select-item-option {
  background-color: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 0px;
}

.custom-drop-down-select .anticon {
  margin-top: 2.5px;
}

.button-color-primary {
  color: #fff;
  background-color: #2499ef;
  width: 100px;
  height: 35px;
}

.media-url-background,
.media-url-background .ant-input {
  font-size: 11px !important;
}

.sider-style {
  text-align: center;
  line-height: 50px;
  color: #333;
  background-color: #f4f4f4 !important;
}

.sider-style1 {
  text-align: "center" !important;
  line-height: "50px" !important;
  color: "#333" !important;
  background-color: "#f4f4f4" !important;
}

.loading-button {
  font-size: 20px;
  color: #fff;
}

.numberlist-sprites {
  background-repeat: no-repeat;
  background: url(../../assets/svg/static-icons.svg);
  min-width: 25px;
  border-radius: 4px;
  min-height: 25px;
  transform: translate(0%, 0%) scale(1);
  background-size: 21rem 21rem;
}
.numberlist-twilio {
  background-position: -36.8rem -5.4rem;
}
.numberlist-ringcentral {
  background-position: -29.6rem -5.4rem;
}
.numberlist-hello_send {
  background-position: -27.78rem -7.19rem;
}
.numberlist-telnyx {
  background-position: -31.4rem -5.4rem;
}
.numberlist-vonage {
  background-position: -33.21rem -5.4rem;
}
.numberlist-ClickSend {
  background-position: -34.97rem -5.4rem;
}
.numberlist-BurstSMS {
  background-position: -38.58rem -5.4rem;
}
.numberlist-plivo {
  background-position: -37.45rem -1.24rem;
}

.WhatsNewImg {
  width: 90px;
  height: 115px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.WhatsNewCard .ant-card .ant-card-cover > * {
  width: auto;
}

.WhatsNewCard .ant-card .ant-card-actions > li:not(:last-child) {
  border-inline-end: 0px;
}

.WhatsNewCard .ant-card .ant-card-meta-title {
  color: #009efd;
}

.WhatsNewCard .ant-btn-primary {
  background-color: #009efd;
}

.WhatsNewCard .ant-card .ant-card-body {
  padding: 0px;
  font-family: "Inter", sans-serif;
}

.WhatsNewCard .ant-card .ant-card-actions {
  border: 0px;
}

.WhatsNewCard .ant-card .ant-card-meta-description {
  font-size: 12px;
  color: black;
}

.WhatsNewCard .ant-ribbon .ant-ribbon-text {
  font-size: 12px;
}

.announcementCardButton {
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.WhatsNewCard .ant-card-bordered {
  border: 0px;
}

.WhatsNewCard .ant-card .ant-card-actions > li {
  margin: 0px;
}

.sendSMSFooter {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.uploadIcon {
  background-position: -204px -610px !important;
}

.sendSMSTextArea::placeholder {
  color: #dcdcdc;
}

.moreMenuDropdownList {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 20px 20px 0px rgba(0, 0, 0, 0.08);
}

.sendSMSMoreMenuIcon {
  background-position: 12% 1.18% !important;
  background-size: 31rem 35rem;
  height: 22px !important;
  width: 22px !important;
}

.templatesDropdownIcon {
  background-position: 6% 1.5%;
}

.numberlist-sprites {
  background-repeat: no-repeat;
  background: url(../../assets/svg/static-icons.svg);
  min-width: 25px;
  border-radius: 4px;
  min-height: 25px;
  transform: translate(0%, 0%) scale(0.9);
  background-size: 21rem 21rem;
}
.numberlist-twilio {
  background-position: -36.8rem -5.4rem;
}
.numberlist-twilio_whatsapp {
  background-position: -31.4rem -7.2rem;
}
.numberlist-ringcentral {
  background-position: -29.6rem -5.4rem;
}
.numberlist-hello_send {
  background-position: -29.6rem -7.19rem;
}
.numberlist-telnyx {
  background-position: -31.4rem -5.4rem;
}
.numberlist-vonage {
  background-position: -33.21rem -5.4rem;
}
.numberlist-ClickSend {
  background-position: -34.97rem -5.4rem;
}
.numberlist-BurstSMS {
  background-position: -38.58rem -5.4rem;
}
.numberlist-plivo {
  background-position: -37.45rem -1.24rem;
}

.WhatsNewCard .ant-card .ant-card-actions > li {
  margin: 0px;
}

.sendSMSFooter {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.uploadIcon {
  background-position: -204px -610px !important;
}

.sendSMSTextArea::placeholder {
  color: #dcdcdc;
}

.moreMenuDropdownList {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 20px 20px 0px rgba(0, 0, 0, 0.08);
}

.sendSMSMoreMenuIcon {
  background-position: 64% 32.28% !important;
  background-size: 31rem 35rem;
  height: 22px !important;
  width: 22px !important;
}

.templatesDropdownIcon {
  background-position: 6% 1.5%;
}

.numberlist-sprites {
  background-repeat: no-repeat;
  background: url(../../assets/svg/static-icons.svg);
  min-width: 25px;
  border-radius: 4px;
  min-height: 25px;
  transform: translate(0%, 0%) scale(0.9);
  background-size: 21rem 21rem;
}
.numberlist-twilio {
  background-position: -36.8rem -5.4rem;
}
.numberlist-twilio_whatsapp {
  background-position: -31.4rem -7.2rem;
}
.numberlist-ringcentral {
  background-position: -29.6rem -5.4rem;
}
.numberlist-hello_send {
  background-position: -29.6rem -7.19rem;
}
.numberlist-telnyx {
  background-position: -31.4rem -5.4rem;
}
.numberlist-vonage {
  background-position: -33.21rem -5.4rem;
}
.numberlist-ClickSend {
  background-position: -34.97rem -5.4rem;
}
.numberlist-BurstSMS {
  background-position: -38.58rem -5.4rem;
}
.numberlist-plivo {
  background-position: -37.45rem -1.24rem;
}

.WhatsNewCard .ant-card .ant-card-actions > li {
  margin: 0px;
}

.sendSMSFooter {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.uploadIcon {
  background-position: -204px -610px !important;
}

.sendSMSTextArea::placeholder {
  color: #dcdcdc;
}

.moreMenuDropdownList {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 20px 20px 0px rgba(0, 0, 0, 0.08);
}

.sendSMSIcon {
  background-position: 1.5% 19.8%;
  min-width: 45px;
}

.sendSMSIconWrapper {
  border-radius: 6px;
  background-color: #2ed47a;
  width: 44px;
  height: 35px;
}

.templatesDropdownIcon {
  background-position: 6% 1.3%;
}

.mediaUploadIcon {
  background-position: 3% 27.4%;
  width: 23px !important;
  transform: translate(0%, 0%) scale(1.3);
}

.mediaUploadIcon {
  background-position: 59.6% 1.4%;
  width: 23px !important;
  height: 25px !important;
}

.addTemplateIcon {
  background-position: 16.5% 5.4%;
}

.border-default {
  outline: 1px solid #cfd3d4;
}

.focus-border {
  border: none;
  transition: none !important;
}

.focus-border:focus-within {
  outline: 1.5px solid rgba(96, 91, 255, 1);
  transition: none;
}

.h-45 {
  height: 45px !important;
}
.h-36 {
  height: 36px !important;
}
.h-44 {
  height: 44px !important;
}

.h-50 {
  height: 50px !important;
}

.ant-select-item-option-disabled:hover {
  cursor: default;
}

.addPhone-sendsms {
  /* display: flex;
  width: 100%;
  justify-content: space-between; */
}
.defaultPhoneStarred {
  top: -2px;
  right: 10px;
  position: absolute;
  z-index: 2;
}
.defaultPhone {
  background: rgba(0, 0, 0, 0.008);
  position: absolute;
  z-index: 2;
  right: 10px;
  display: none;
  border-radius: 37px;
  color: #6361c6;
  font-weight: 600;
}
.defaultPhone.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #6361c6;
}
.addPhone-sendsms:hover {
  .defaultPhone {
    display: block;
  }
}
.addPhoneSwitch-chatView {
  height: 11px;
  width: 20px;
  min-width: 36px;
}
.addPhoneSwitch-chatView .ant-switch-handle {
  top: -4.8px;
  inset-inline-start: 0px;
  width: 20px;
  height: 20px;
}
.addPhoneSwitch-chatView.ant-switch.ant-switch-checked {
  background: #78ea93 !important;
}
.addPhoneSwitch-chatView.ant-switch.ant-switch-checked.ant-switch
  .ant-switch-handle::before {
  border-radius: 37px;
  background: #35c658;
}
.tenDlc-icon {
  background-position: 2.4% 6.5%;
  height: 18px !important;
  width: 45px !important;
  background-size: 33rem 38rem;
}
.ant-select-selection-item .labelMode {
  display: none !important;
}

.plusIconWhite {
  background-position: 16% 5.55%;
}

.mediaUploadIconModern {
  background-position: 2.5% 27.25%;
  scale: 1.3;
}

.bg-white {
  background-color: white !important;
}
