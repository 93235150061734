:root {
  --hs-padding-1: 0.813rem;
}

/* ############################## Font ############################## */
/* ::::::: Font size ::::::: */

.hs-fs-11 {
  font-size: 11px !important;
}

.hs-fs-12 {
  font-size: 12px !important;
}

.hs-fs-13 {
  font-size: 13px !important;
}

.hs-fs-14 {
  font-size: 14px !important;
}

.hs-fs-15 {
  font-size: 15px !important;
}

.hs-fs-16 {
  font-size: 16px !important;
}

.hs-fs-17 {
  font-size: 17px !important;
}

.hs-fs-18 {
  font-size: 18px !important;
}

.hs-fs-22 {
  font-size: 22px !important;
}

/* ::::::: Font weight ::::::: */

.hs-fw-550 {
  font-weight: 550 !important;
}

.hs-fw-600 {
  font-weight: 600 !important;
}

.hs-fw-800 {
  font-weight: 800 !important;
}

/* ############################## COLORS ############################## */

.hs-color-violet {
  color: var(--hs-violet) !important;
}

.hs-bg-v-light-grey {
  background: var(--hs-v-light-grey);
}

.hs-bg-violet {
  background-color: var(--hs-violet) !important;
}

.hs-text-muted {
  color: rgba(129, 128, 148) !important;
}

.hs-bg-white {
  background-color: #fff !important;
}

.hs-bg-offwhite {
  background-color: #fafafb !important;
}

.hs-color-dark-blue {
  color: var(--hs-dark-blue) !important;
}

.hs-bg-light-orange {
  background-color: var(--hs-light-orange);
}

.hs-color-dark-orange {
  color: var(--hs-dark-orange);
}

.hs-color-dark-grey {
  color: var(--hs-dark-grey);
}

.hs-color-light-blue-2 {
  color: var(--hs-light-blue-2);
}

.hs-color-red {
  color: var(--hs-red);
}

.hs-bg-v-light-red {
  color: var(--hs-v-light-red);
}

.hs-color-light-silver {
  color: var(--hs-light-silver);
}

.hs-bg-light-silver {
  background-color: var(--hs-light-silver);
}

.hs-bg-dark-grey {
  background: var(--hs-dark-grey);
}

.hs-bg-light-grey {
  background-color: var(--hs-light-grey);
}

.hs-border-dark-grey {
  border: 1px solid #e4e5e8;
}

/* ############################## ONE_LINERS ############################## */
.all-unset {
  all: unset !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hs-hr {
  border-top: 0.9px solid #00001290;
}

.hs-w-fit {
  width: fit-content !important;
}

.hs-spin-360-infinite {
  animation: rotate-animation 1s cubic-bezier(0.25, 0.75, 0.75, 1) infinite;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ############################## BORDERS ############################## */

.hs-border-10 {
  border-radius: 10px !important;
}

.hs-border-5 {
  border-radius: 5px !important;
}

.hs-border-12 {
  border-radius: 12px !important;
}

.hs-border-20 {
  border-radius: 20px !important;
}

.hs-border-30 {
  border-radius: 30px !important;
}

/* ::::::: Custom border style ::::::: */

.hs-focus-border {
  border: none;
  transition: none;
}

.border-none,
.border-none:focus-visible {
  outline: none !important;
  border: none !important;
}

.border-none > :not(.profileAvatarBg) {
  visibility: hidden !important;
}

.hs-focus-border:focus-within {
  outline: 2px solid var(--hs-violet);
}

.hs-focus-border-red:focus-within {
  outline: 2px solid var(--hs-red) !important;
}

/* ############################## WIDTHS AND HEIGHTS ############################## */

.hs-mw-85 {
  max-width: 85% !important;
}

.hs-w-90 {
  width: 90% !important;
}

.hs-w-95 {
  width: 95% !important;
}

.hs-w-98 {
  width: 98% !important;
}

.hs-h-65 {
  height: 65% !important;
}

/* ############################## CUSTOM PADDINGS AND MARGINS ############################## */

.hs-px-1 {
  padding-left: var(--hs-padding-1);
  padding-right: var(--hs-padding-1);
}

/* ############################## CUSTOM CSS ############################## */

.avatar-small-round {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-big-round {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profilePopup > .ant-dropdown-menu-root {
  left: -110px;
  position: absolute;
}

input::placeholder {
  font-size: 15px !important;
}

textarea::placeholder {
  font-size: 15px !important;
}

.bs-vr-override {
  opacity: 1 !important;
  width: 4px !important;
}

.hs-text-decoration-none {
  text-decoration: none;
}

/* ############################## ICONS ############################## */

.closeIconSmall {
  background-position: 16% 1.5%;
  border: none;
  background-color: transparent;
}

.closeIconBig {
  height: 24px !important;
  width: 24px !important;
  background-position: 16% 1.45%;
  background-size: 42rem;
}
.closeIconBlack {
  height: 24px !important;
  width: 24px !important;
  background-position: 16% 1.3% !important;
  transform: scale(0.8);
}
.deleteIcon {
  height: 70px !important;
  width: 68px !important;
  background-position: -6.4rem -26.7rem;
  background-size: 45rem;
  transform: translate(2%, 0%) scale(0.7);
}

.pauseIconBig {
  width: 76px !important;
  height: 57px !important;
  background-position: 16.8% 44%;
}
.campaignEdit {
  width: 62px !important;
  height: 57px !important;
  background-position: 94.8% 44%;
}
.disConnectIcon {
  height: 70px !important;
  background-position: 80.9% 43.3%;
  width: 56px !important;
}

.documentIconBlue {
  height: 30px !important;
  background-position: 47.5% 32.3%;
  width: 30px !important;
}

.photosIconBlue {
  height: 30px !important;
  background-position: 47.5% 26.3%;
  width: 30px !important;
}

.profileMoreMenuIcon {
  background-position: 35% 18%;
}

.fromNumberDropDownIcon {
  background-position: 0.8% 0.7%;
  transform: translate(2%, 0%) scale(0.8);
}

.infoIconViolet {
  background-position: 64% 50.7%;
}

.infoIconRed {
  background-position: 70% 50.7%;
}
.hs-fw-600 {
  font-weight: 600 !important;
}
.search-icon {
  background-position: 55% 9.5% !important;
}

.custom-anchor {
  color: var(--hs-violet);
  text-decoration: none;
}
.custom-anchor:hover {
  color: var(--hs-violet);
  opacity: 80%;
}

.customComponentDropDownIcon {
  background-position: 0% 1%;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #e2e8f0;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e2e8f0;
}
.boxshadowRemove {
  box-shadow: none !important;
}
.unsaved-modal-icon {
  background-position: 30.5% 55.5%;
  height: 65px !important;
  width: 65px !important;
  cursor: default;
}
