.serviceSidebar {
  background-color: #f9f9f9;
  color: rgba(195, 202, 217, 1);
  padding: 2rem 1rem !important;
  margin: 10px 0px !important;
}
.sidebarMenu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: 0px;
}
.sidebarMenu.ant-menu-light .ant-menu-item-selected {
  color: #29cc39 !important;
  background-color: rgba(41, 204, 57, 0.1);
}

.sidebarMenu.ant-menu-light .ant-menu-item {
  color: #030229;
  font-size: 13px;
}
.sidebarMenu.ant-menu-light
  .ant-menu-item-selected
  > .ant-menu-title-content
  > a
  > div
  > .right-arrow {
  background-image: url("../../svg/action-icons.svg");
  height: 25px;
  width: 25px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: -200px -60px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.serviceSidebar > .ant-menu-title-content > a > div > .right-arrow {
  background-image: url("../../svg/action-icons.svg");
  height: 25px;
  width: 25px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: -340px -60.7px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.sidebarMenuItemBadge {
  background-color: #605bff;
  top: 24px;
  padding: 0 4px;
  font-size: 10px;
  line-height: 16px;
}
