.campaignOverviewIcon {
  height: 62px !important;
  width: 62px !important;
  background-size: 37rem;
}

.totalCampaignsIcon {
  background-position: 33.5% 17%;
  background-size: 47rem;
}

.messagesSentIcon {
  background-position: 3.6% 16.2%;
}

.subscribersIcon {
  background-position: 18.5% 41.2%;
}

.optOutIcon {
  background-position: 3.5% 41.4%;
}

.h-90 {
  height: 90%;
}

.sendRocketWhite {
  background-position: 65.3% 9.7%;
}

.campaignOverviewTable > div > div > div > .ant-table-container {
  box-shadow: none !important;
  min-height: 73dvh;
  width: fit-content;
}

.campaignOverviewTable
  > div
  > div
  > div
  > .ant-table-container
  > .ant-table-tbody {
  position: unset !important;
}

.campaignOverviewTable .ant-table-thead > tr > th {
  background-color: #fff;
}
.overviewTableCells {
  color: #030229 !important;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  border-bottom: 0px !important;
}

.campaignTitle {
  width: 100px;
}

.navigateToCampaignButton {
  height: 3rem;
  width: 9rem;
}

.ant-table-thead > tr > .overviewTableCells {
  font-size: 13px;
  border-bottom: 1px solid #03022910 !important;
  font-weight: 400 !important;
}

.ant-table-thead > tr > .overviewTableCells::before {
  display: none !important;
}

.overviewTableRow {
  padding-bottom: 0.3rem;
}

.campaignBadge {
  text-transform: lowercase;
}

.campaignBadge::first-letter {
  text-transform: uppercase;
}

.immediateBadge {
  background-color: rgba(var(--badge-light-blue), 0.1);
  color: rgba(var(--badge-light-blue));
}

.scheduledBadge {
  background-color: rgba(var(--badge-light-red), 0.1);
  color: rgba(var(--badge-light-red));
}

.overviewTableRow:hover > .overviewTableCells,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText > *,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText > * > * {
  color: var(--hs-violet) !important;
}

.overviewTableRow:hover
  > .overviewTableCells
  > .cellInnerText
  > .previewEyeIcon {
  visibility: visible;
}

.sendingOrScheduledStatus {
  background-color: rgba(var(--badge-light-green), 0.1) !important;
  color: rgb(var(--badge-light-green)) !important;
}

.sentStatus {
  background-color: var(--hs-v-light-blue) !important;
  color: var(--hs-blue) !important;
}

.pendingStatus {
  background-color: rgba(var(--badge-light-grey), 0.1) !important;
  color: rgb(var(--badge-light-grey)) !important;
}

.cellInnerText {
  color: #030229 !important;
  font-size: 14px;
  font-weight: 500;
}

.rocketWithAirIcon {
  height: 5rem;
  width: 3.2rem;
  background-position: -2.1rem -32rem;
  background-size: 40rem;
}

.metricContainer {
  height: 110px !important;
  width: 250px !important;
}

.previewEyeIcon {
  visibility: hidden;
  background-position: 67.5% 18.8%;
}

.createdUser {
  height: 37px;
  width: 37px;
  border: 2px solid gold;
}

.activityDetailsModal {
  width: 37dvw !important;
}

.activityDetailList {
  width: 100%;
  max-height: 40vh !important;
  overflow: scroll;
}
