.summaryTableWrapper {
  width: 92% !important;
}

.searchIcon {
  cursor: pointer;
}

.ant-table-cell {
  cursor: pointer;
}

.ant-table-container {
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
}

.ant-tabs-nav {
  box-sizing: border-box !important;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #212529c9 !important;
}

.licenseDrawer > .ant-drawer-content-wrapper > .ant-drawer-content,
.licenseDrawer > .ant-drawer-content-wrapper {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
}

.antd-tag {
  height: 32px;
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
}
.seeMoreButtonDiv {
  display: flex;
  justify-content: flex-end;
}
.seeMoreButton {
  color: #1677ff;
  border: none;
}
.licenseDataDiv {
  margin-bottom: 1rem;
}
.licenseDrawer > .ant-drawer-mask {
  position: static !important ;
}
.longString {
  word-wrap: break-word;
}
.ant-drawer:has(.removeOutlineAntDrawer) {
  outline: none;
}
