.input-form-item {
  width: 300px !important;
}

.setting-radio-btn.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--hs-violet);
  border-color: var(--hs-violet);
}

.setting-radio-btn.ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner::after {
  display: inline-block;
  transform: rotate(45deg);
  height: 11px;
  width: 5px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 12.5px;
  background: var(--hs-violet);
  top: 9px;
  border-radius: 1.5px;
}
.checkbox-settings .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--hs-violet) !important;
  border-color: var(--hs-violet) !important;
}
.checkbox-settings .ant-checkbox .ant-checkbox-inner {
  border-radius: 15px !important;
}
.checkbox-settings .ant-checkbox .ant-checkbox-inner:after {
  border: 1.5px solid #fff;
  border-top: 0;
  border-left: 0;
}

.checkbox-settings
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  border-color: var(--hs-violet) !important;
}
.email-list-setting .ant-spin-container {
  display: flex;
  align-items: center;
}
.email-list-item-setting {
  outline: 1px solid #c2cfe0;
  border-radius: 10px;
  font-size: 12px;
  color: var(--hs-dark-grey);
}

.mask-off-setting {
  background-color: aqua;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0%;
  z-index: 2;
}
.mask-on-component {
  z-index: 4;
  position: absolute;
}
.sidebar-menu {
  width: 200px !important;
  padding-left: 5px !important;
  border-right: 1px solid #e6e7ec !important;
}
