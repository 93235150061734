body {
  font-family: "Inter", sans-serif, sans-serif;
}
#tokenInput::placeholder,
#sidInput::placeholder {
  font-size: 14px;
}
.rightServiceLogo {
  margin-top: 65px;
}
#sidInput {
  width: 90%;
  height: 48px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #f7f7f8;
}
#tokenInput {
  background-color: #f7f7f8;
  width: 90%;
  height: 48px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 30px;
}
#loginContent {
  font-size: 12px;
  font-family: "Inter", sans-serif, sans-serif;
  margin-top: 100px;
  margin-bottom: 50px;
  width: 460px;
}
.auth-btn {
  width: 10rem;
  height: 3.2rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 12px;
}
.auth-btn.ant-btn-primary {
  background-color: #605bff;
}
.box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
}
.label-authType {
  margin-left: 4px;
}
.fontStyle {
  font-family: "Inter", sans-serif, sans-serif;
  font-weight: 500;
  color: #474747;
}
.invalid {
  font-size: 11px;
  color: #ee3248;
  position: absolute;
  margin-left: 2px;
  margin-top: 50px;
  padding: 1px;
  font-weight: 500;
}
#mismatch {
  color: #ee3248;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  padding: 1px;
  margin: 10px 40px;
}
.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}
.taskProgress.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.taskProgress.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #009efd;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #009efd;
}
.ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #009efd;
}
@media (max-width: 600px) {
  .rightServiceLogo {
    margin-top: 0%;
  }
}
#appTitle {
  font-size: 14px;
  width: 90%;
  margin: 1rem 0rem 2rem 0.2rem;
  color: #030229;
  display: flex;
  align-items: center;
}
#authorizebuttonringcentral {
  background-color: #605bff;
}
.trialAccount {
  border: 1.2px solid #ff9800;
  border-radius: 8px;
  color: #ff9800;
  width: 80%;
  margin-top: 1.5rem;
  background-color: #fff5e3;
}
.authInfo-icon {
  background-position: -24.95rem -5.95rem;
  min-width: 25px;
}

.disconnectModal .ant-modal-close-x {
  display: none;
}
